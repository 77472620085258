import Axios from "axios";

// dev
//Axios.defaults.baseURL = "https://localhost:7277/api/";

// QA
//AppUrl = "http://qa.smil-e.com";(FYI)
//Axios.defaults.baseURL = "http://qaapi.smil-e.com/api/";

//Production (Azure)
// AppUrl = "https://smil-e.com";
Axios.defaults.baseURL = "https://api.smil-e.com/api/";

Axios.defaults.headers.common["Authorization"] =
  "Bearer " + localStorage.getItem("token");

export default class AxiosServices {
  post(url, data, Header = false) {
    return Axios.post(url, data, Header);
  }
  get(url, header = null) {
    return Axios.get(url, header);
  }
}
